import { createSharedComposable } from '@vueuse/core'
import { createDirectus, readSingleton, rest } from '@directus/sdk'

const _usePageData = () => {
  const config = useRuntimeConfig()
  const client = createDirectus(config.public.directus.url).with(rest())

  async function fetchPageData() {
    return await client.request(
      readSingleton('homepage', {
        fields: ['*.*.*'],
        deep: {
          translations: {
            _filter: {
              _and: [
                {
                  languages_code: { _eq: 'cz' }
                }
              ]
            }
          }
        }
      })
    )
  }

  return {
    fetchPageData
  }
}

export const usePageData: any = createSharedComposable(_usePageData)
