<script setup lang="ts">
const { fetchPageData } = usePageData()
const { fetchFeaturesData } = useFeaturesData()
const { fetchPricingData } = usePricingData()
const { fetchFaqsData } = useFaqsData()

const { data: homePageData } = await useAsyncData<HomePageData>('index', () => fetchPageData())
const { data: homePricingData } = await useAsyncData<PricingResponse[]>('pricing', () => fetchPricingData())
const { data: homeFeaturesData } = await useAsyncData<Feature[]>('features', () => fetchFeaturesData())
const { data: homeFaqsData }: any = await useAsyncData('faqs', () => fetchFaqsData())

// useSeoMeta({
//   titleTemplate: '',
//   title: page.value.title,
//   ogTitle: page.value.title,
//   description: page.value.description,
//   ogDescription: page.value.description
// })
</script>

<template>
  <div v-if="homePageData">
    <ULandingHero>
      <div
        class="absolute inset-0 landing-grid z-[-1] [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      />
      <template #title>
        Journaledge
      </template>
      <div
        class="-mt-14 text-xl text-center tracking-tight text-gray-600 dark:text-gray-300"
        v-html="homePageData.translations[0].description"
      />
      <div
        class="-mt-10 text-lg text-center tracking-tight dark:text-white"
        v-html="homePageData.translations[0].content"
      />
    </ULandingHero>

    <div class="flex my-20 justify-center">
      <UButton size="xl">
        {{ $t('enterApp') }}
      </UButton>
    </div>

    <ULandingSection class="!pt-0">
      <video
        id="homepageVideo"
        autoplay
        muted
        playsinline
        loop
        class="rounded-[50px] d-none d-sm-block"
      >
        <source
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
          type="video/mp4"
        >
      </video>
    </ULandingSection>

    <a id="functions" />
    <ULandingSection
      v-for="(section, index) in homePageData.translations[0].cards"
      :key="index"
      :title="section.heading"
      :description="section.description"
      :align="section.align"
      :features="section.points"
    >
      <ImagePlaceholder />
    </ULandingSection>

    <div class="flex my-20 justify-center">
      <UButton>Button</UButton>
    </div>

    <a id="supportedPlatforms" />
    <ULandingSection
      :title="homePageData.translations[0].services_title"
      :description="homePageData.translations[0].services_desciption"
    >
      <UPageGrid>
        <ULandingCard
          v-for="(item, index) in homeFeaturesData"
          :key="index"
          v-bind="{
            title: item.translations[0].name,
            orientation: 'horizontal',
            description: item.translations[0].description
          }"
        >
          <img
            :src="item.translations[0].logo"
            :class="item.translations[0].class"
            alt="est"
          >
        </ULandingCard>
      </UPageGrid>
    </ULandingSection>

    <a id="pricing" />
    <ULandingSection
      :title="homePageData.translations[0].pricing_heading"
      :description="homePageData.translations[0].pricing_description"
    >
      <UPricingGrid compact>
        <UPricingCard
          v-for="price in homePricingData[0].translations[0].pricing[0].prices"
          :key="price.price_name"
          :title="price.price_name"
          :price="`${price.price} CZK`"
          :description="price.price_description"
          :features="price.price_points.map(prc => prc.price_benefit)"
        />
      </UPricingGrid>
    </ULandingSection>

    <a id="faqs" />
    <ULandingSection
      :title="homePageData.translations[0].faq_title"
      :description="homePageData.translations[0].faq_description"
    >
      <ULandingFAQ
        :items="homeFaqsData.map(faq => ({ label: faq.translations[0].label, content: faq.translations[0].content }))"
        multiple
      />
    </ULandingSection>

    <!--    <ULandingSection -->
    <!--      :headline="page.testimonials.headline" -->
    <!--      :title="page.testimonials.title" -->
    <!--      :description="page.testimonials.description" -->
    <!--    > -->
    <!--      <UPageColumns class="xl:columns-4"> -->
    <!--        <div -->
    <!--          v-for="(testimonial, index) in page.testimonials.items" -->
    <!--          :key="index" -->
    <!--          class="break-inside-avoid" -->
    <!--        > -->
    <!--          <ULandingTestimonial -->
    <!--            v-bind="testimonial" -->
    <!--            class="bg-gray-100/50 dark:bg-gray-800/50" -->
    <!--          /> -->
    <!--        </div> -->
    <!--      </UPageColumns> -->
    <!--    </ULandingSection> -->

    <!--    <ULandingSection> -->
    <!--      <ULandingCTA -->
    <!--        v-bind="page.cta" -->
    <!--        class="bg-gray-100/50 dark:bg-gray-800/50" -->
    <!--      /> -->
    <!--    </ULandingSection> -->
  </div>
</template>

<style scoped>
.landing-grid {
  background-size: 100px 100px;
  background-image: linear-gradient(to right, rgb(var(--color-gray-200)) 1px, transparent 1px),
  linear-gradient(to bottom, rgb(var(--color-gray-200)) 1px, transparent 1px);
}

.dark {
  .landing-grid {
    background-image: linear-gradient(to right, rgb(var(--color-gray-800)) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(var(--color-gray-800)) 1px, transparent 1px);
  }
}
</style>
